:root {
  /* // COLORS */

  --nav-base-color-teal-100: hsl(192, 66%, 95%);
  --nav-base-color-teal-200: hsl(192, 66%, 88%);
  --nav-base-color-teal-300: hsl(192, 66%, 76%);
  --nav-base-color-teal-400: hsl(192, 66%, 55%);
  --nav-base-color-teal-450: hsl(194, 77%, 45%);
  --nav-base-color-teal-500: hsl(196, 77%, 35%);
  --nav-base-color-teal-600: hsl(196, 77%, 30%);
  --nav-base-color-teal-700: hsl(196, 77%, 25%);
  --nav-base-color-teal-800: hsl(196, 77%, 20%);
  --nav-base-color-teal-900: hsl(196, 77%, 15%);

  --nav-base-color-yellow-100: hsl(46, 90%, 95%);
  --nav-base-color-yellow-200: hsl(46, 100%, 83.33%);
  --nav-base-color-yellow-300: hsl(46, 100%, 75.1%);
  --nav-base-color-yellow-400: hsl(46, 100%, 66.67%);
  --nav-base-color-yellow-500: hsl(46, 100%, 50%);
  --nav-base-color-yellow-600: hsl(42, 100%, 55%);
  --nav-base-color-yellow-700: hsl(40 100% 46%);

  --nav-base-color-orange-100: hsl(31, 97%, 90%);
  --nav-base-color-orange-300: hsl(31, 97%, 80%);
  --nav-base-color-orange-400: hsl(31, 97%, 69%);
  --nav-base-color-orange-500: hsl(31, 97%, 60%);
  --nav-base-color-orange-600: hsl(31, 97%, 50%);
  --nav-base-color-orange-700: hsl(31, 97%, 45%);

  --nav-base-color-green-100: hsl(161, 73%, 95%);
  --nav-base-color-green-200: hsl(161, 73%, 88%);
  --nav-base-color-green-300: hsl(161, 73%, 66%);
  --nav-base-color-green-400: hsl(161, 73%, 55%);
  --nav-base-color-green-500: hsl(161, 73%, 48%);
  --nav-base-color-green-600: hsl(161, 73%, 40%);
  --nav-base-color-green-700: hsl(161, 73%, 35%);
  --nav-base-color-green-900: hsl(161, 73%, 25%);

  --nav-base-color-red-100: hsl(0, 100%, 90%);
  --nav-base-color-red-200: hsl(353, 100%, 85%);
  --nav-base-color-red-250: hsl(353, 100%, 75%);
  --nav-base-color-red-300: hsl(353, 97%, 69%);
  --nav-base-color-red-350: hsl(353, 97%, 60%);
  --nav-base-color-red-500: hsl(353, 97%, 55%);
  --nav-base-color-red-700: hsl(353, 97%, 40%);
  --nav-base-color-red-900: hsl(353, 97%, 34%);

  --nav-base-color-blue-100: hsl(230, 100%, 96%);
  --nav-base-color-blue-500: hsla(230, 80%, 63%, 1);

  --nav-base-color-grey-50: hsl(0, 0%, 100%);
  --nav-base-color-grey-75: hsl(0, 0%, 96%);
  --nav-base-color-grey-100: hsl(0, 0%, 93%);
  --nav-base-color-grey-150: hsl(0, 0%, 90%);
  --nav-base-color-grey-200: hsl(0, 0%, 80%);
  --nav-base-color-grey-250: hsl(0, 0%, 75%);
  --nav-base-color-grey-300: hsl(0, 0%, 70%);
  --nav-base-color-grey-400: hsl(0, 0%, 60%);
  --nav-base-color-grey-500: hsl(0, 0%, 45%);
  --nav-base-color-grey-600: hsl(0, 0%, 32%);
  --nav-base-color-grey-700: hsl(0, 0%, 20%);
  --nav-base-color-grey-800: hsl(0, 0%, 11%);
  --nav-base-color-grey-900: hsl(0, 0%, 0%);

  --nav-base-color-rotate-0: hsl(196, 77%, 35%);
  --nav-base-color-rotate-1: hsl(31, 97%, 69%);
  --nav-base-color-rotate-2: hsl(261, 50%, 57%);
  --nav-base-color-rotate-3: hsl(16, 77%, 35%);
  --nav-base-color-rotate-4: hsl(105, 39%, 62%);
  --nav-base-color-rotate-5: hsl(46, 100%, 50%);
  --nav-base-color-rotate-6: hsl(217, 62%, 12%);
  --nav-base-color-rotate-7: hsl(21, 50%, 57%);
  --nav-base-color-rotate-8: hsl(196, 78%, 25%);
  /* // PLOTLY doesn't accept this shade in HSL, must be defined in RGB instead */
  --nav-base-color-rotate-9: rgb(219, 53, 94);
  --nav-base-color-rotate-10: hsl(136, 78%, 25%);
  --nav-base-color-rotate-11: hsl(0, 0%, 45%);
  --nav-base-color-rotate-12: hsl(0, 0%, 75%);
  --nav-base-color-rotate-13: hsl(166, 39%, 52%);
  --nav-base-color-rotate-14: hsl(261, 100%, 86%);
  --nav-base-color-rotate-15: hsl(8, 67%, 52%);

  /* // FONTS */

  --nav-base-font-size-xs: 10px;
  --nav-base-font-size-sm: 12px;
  --nav-base-font-size-md: 14px;
  --nav-base-font-size-lg: 16px;
  --nav-base-font-size-xl: 18px;
  --nav-base-font-size-2xl: 20px;
  --nav-base-font-size-3xl: 24px;
  --nav-base-font-size-4xl: 32px;
  --nav-base-font-size-5xl: 48px;

  --nav-base-font-weight-thin: 100;
  --nav-base-font-weight-lighter: 200;
  --nav-base-font-weight-light: 300;
  --nav-base-font-weight-regular: 400;
  --nav-base-font-weight-medium: 500;
  --nav-base-font-weight-bold: 700;
  --nav-base-font-weight-bolder: 800;
  --nav-base-font-weight-thick: 900;

  --nav-base-font-family-default: Lato, "Trebuchet MS", system-ui, sans-serif;
  --nav-base-font-family-heading: Montserrat, sans-serif;
  --nav-base-font-family-monospace: monospace;

  /* // ICONS */

  --nav-base-icon-size-xs: 12px;
  --nav-base-icon-size-sm: 16px;
  --nav-base-icon-size-md: 20px;
  --nav-base-icon-size-lg: 26px;
  --nav-base-icon-size-xl: 32px;
  --nav-base-icon-size-2xl: 48px;
  --nav-base-icon-size-3xl: 56px;

  /* // BORDER RADIUS */

  --nav-base-border-radius-sm: 4px;
  --nav-base-border-radius-md: 8px;
  --nav-base-border-radius-lg: 16px;
  --nav-base-border-radius-pill: 1000px;
  --nav-base-border-radius-elliptic: 50%;

  /* // BORDER WIDTH */

  --nav-base-border-width-light: 1px;
  --nav-base-border-width-regular: 2px;
  --nav-base-border-width-thick: 4px;

  /* // SPACING */

  --nav-base-spacing-1: 1em;
  --nav-base-spacing-2: 2em;
  --nav-base-spacing-4: 4em;
  --nav-base-spacing-4xs: 2px;
  --nav-base-spacing-3xs: 4px;
  --nav-base-spacing-2xs: 6px;
  --nav-base-spacing-xs: 8px;
  --nav-base-spacing-sm: 12px;
  --nav-base-spacing-md: 16px;
  --nav-base-spacing-lg: 20px;
  --nav-base-spacing-xl: 24px;
  --nav-base-spacing-2xl: 36px;
  --nav-base-spacing-3xl: 48px;
  --nav-base-spacing-4xl: 64px;

  /* // SHADOWS */
  --nav-base-shadow-off: 0 0 0 #00000000;
  --nav-base-shadow-sm: 0 2px 2px #00000033;
  --nav-base-shadow-md: 0 4px 4px #00000033;
  --nav-base-shadow-lg: 0 4px 4px #00000055, 0 16px 16px #00000033;
  --nav-base-shadow-top-md: 0 -4px 4px #00000033;

  /* // CURSORS */
  --nav-base-cursor-ew-zoom: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0L10 20" stroke="black" stroke-width="2"/><path d="M4 10H16" stroke="black" stroke-width="2"/><path d="M14 13L17 10L14 7" stroke="black" stroke-width="1.5"/><path d="M6 7L3 10L6 13" stroke="black" stroke-width="1.5"/></svg>')
      10 10,
    auto;

  /* // Z-INDEX */

  --nav-z-index-page-header: 145;
  --nav-z-index-left-sider: 150;
  --nav-z-index-navigation-header: 160;
  --nav-z-index-backdrop: 1028;
  --nav-z-index-bottom-bar: 1030;
  --nav-z-index-bottom-drawer: 1035;
  --nav-z-index-whats-new-box: 1037;
  --nav-z-index-top-drawer: 1040;
  --nav-z-index-modal: 1050;
}
