@import "~/ui/palette/PaletteBase.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .no-spin::-webkit-outer-spin-button,
  .no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-spin[type="number"] {
    -moz-appearance: textfield;
  }

  .horizontalScrollGradient {
    background:
      linear-gradient(90deg, #ffffff 33%, rgba(255, 255, 255, 0)),
      linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff 66%) 100% 0,
      radial-gradient(farthest-side at 0% 50%, rgba(128, 128, 128, 0.4), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 100% 50%, rgba(128, 128, 128, 0.4), rgba(0, 0, 0, 0)) 100% 50%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size:
      50px 100%,
      50px 100%,
      18px 100%,
      18px 100%;
  }
}

@layer scrollbars {
  /* width */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
    border: solid 1px #33333333;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: 2px 1px 2px 1px;
    // background-color: #;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
    // border: solid 2px #999;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

/* Libraries fixes & overrides */

.js-plotly-plot .plotly .modebar-group {
  display: flex !important;
}

.react-datepicker {
  .date-picker-header {
    .month-selector,
    .year-selector {
      & > button[type="button"] {
        padding: 4px;
      }
      & > select {
        padding: 4px;
      }
    }
  }
}

.ReactQueryDevtoolsPanel pre {
  color: inherit;
}

.plotly-notifier {
  display: none;
}

// fix for react-datepicker positioning in no-portal mode
.react-datepicker__tab-loop {
  position: absolute;
}

#HW_frame_cont {
  /*  Headway iframe */
  z-index: var(--nav-z-index-whats-new-box) !important;
  position: fixed !important;
  left: calc(100% - 350px) !important;
  right: 10px !important;
  top: 70px !important;
}

@media (max-width: 999px) {
  #HW_frame_cont {
    top: 45px !important;
  }
}

/* Global */

@media print {
  body {
    overflow: visible !important;

    #app {
      display: block !important;
    }
  }
}
